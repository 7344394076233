import { useSharedComponentsTranslate } from '@vcc-www/shared-dictionaries';
import { translateCapability } from '../../utils/translateCapability';
import { PillGroup } from '../components/PillGroup';
import type { Capability } from '../types/retailerCapabilities';

type CapabilityPillsProps = { capabilities: Capability[] };

export const CapabilityPills = ({ capabilities }: CapabilityPillsProps) => {
  const translate = useSharedComponentsTranslate();
  const titles = capabilities.map((capability) =>
    translateCapability(capability, translate),
  );
  return <PillGroup pillTitles={titles} hasHorizontalScroll={false} />;
};
