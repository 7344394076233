/* eslint-disable @typescript-eslint/no-namespace */
import type { OpeningHours, TimeInterval } from '../src/types/graphql';

declare global {
  // biome-ignore lint/style/noNamespace:
  namespace Intl {
    interface Locale {
      weekInfo?: {
        firstDay: number;
        minimalDays: number;
        weekend: [number, number];
      };
    }
  }
}

const mondayStartWeek = [
  'monday',
  'tuesday',
  'wednesday',
  'thursday',
  'friday',
  'saturday',
  'sunday',
];

const sundayStartWeek = [
  'sunday',
  'monday',
  'tuesday',
  'wednesday',
  'thursday',
  'friday',
  'saturday',
];

type CombinedDay = {
  days: {
    startDay?: string;
    endDay?: string;
  };
  hours?: string[];
};

type GetCombinedOpeningHoursProps = {
  openingHours: Omit<OpeningHours, 'specialDates' | '__typename'>;
  locale: string;
  combineDays?: boolean;
  firstDayOfWeek?: 'monday' | 'sunday';
  hour12?: boolean;
};

export const getCombinedOpeningHours = ({
  openingHours,
  locale,
  combineDays,
  firstDayOfWeek = 'monday',
  hour12 = false,
}: GetCombinedOpeningHoursProps): CombinedDay[] => {
  const weekDays =
    firstDayOfWeek === 'monday' ? mondayStartWeek : sundayStartWeek;
  const format = new Intl.DateTimeFormat(locale, {
    weekday: 'long',
    timeZone: 'UTC',
  }).format;
  const localizedWeekDays = weekDays.reduce(
    (acc, day, i) => {
      // We choose a date which we know starts on a monday vs sunday
      acc[day] = format(
        new Date(
          firstDayOfWeek === 'monday'
            ? Date.UTC(2021, 5, i)
            : Date.UTC(2021, 1, i),
        ),
      );
      return acc;
    },
    {} as Record<string, string>,
  );

  const openingHoursArray: { [x: string]: TimeInterval[] }[] = Object.entries(
    openingHours,
  ).map(([key, value]) => ({ [key]: value }));

  const sortedOpeningHoursArray = openingHoursArray.sort((a, b) => {
    const dayA = Object.keys(a)[0];
    const dayB = Object.keys(b)[0];
    return weekDays.indexOf(dayA) - weekDays.indexOf(dayB);
  });
  const formattedDays: CombinedDay[] = sortedOpeningHoursArray
    .map((obj) => {
      const day = Object.keys(obj)[0];
      const values = Object.values(obj)[0];
      // Check needed due to typescripts inability to mark additional props as invalid for openingHours
      const localizedDay = localizedWeekDays[day];
      return !localizedDay
        ? null
        : {
            days: {
              startDay: localizedDay,
              endDay: undefined,
            },
            hours: values
              .map((openingHour) =>
                getHours({ oH: openingHour, hour12, locale }),
              )
              .filter(Boolean),
          };
    })
    .filter(Boolean);

  const combinedDays: CombinedDay[] = [];
  if (combineDays) {
    // biome-ignore lint/complexity/noForEach: <explanation>
    formattedDays.forEach((day) => {
      const previousCombinedDay = combinedDays.at(combinedDays.length - 1);

      if (
        previousCombinedDay &&
        previousCombinedDay.hours?.join() === day.hours?.join() &&
        day.days.startDay
      ) {
        previousCombinedDay.days.endDay = day.days.startDay;
      } else {
        combinedDays.push(day);
      }
    });
  }

  return combinedDays.length > 0 ? combinedDays : formattedDays;
};

type FormatHourProps = {
  hour: string;
  hour12: boolean;
  locale: string;
};

const formatHour = ({ hour, hour12, locale }: FormatHourProps) => {
  return new Date(`1990-07-15T${hour}Z`)
    .toLocaleTimeString(locale, {
      timeZone: 'UTC',
      hour12,
      hour: 'numeric',
      minute: 'numeric',
    })
    .toUpperCase();
};

type GetHoursProps = {
  oH: TimeInterval;
  hour12: boolean;
  locale: string;
};

const getHours = ({ oH, hour12, locale }: GetHoursProps) => {
  const start = formatHour({ hour: oH.start, hour12, locale });
  const end = formatHour({ hour: oH.end, hour12, locale });

  return oH.start && oH.end ? `${start}-${end}` : undefined;
};
