import type { Lead } from '@volvo-cars/tracking';
import type { Retailer } from '../src/types/retailer';

// Returns a GA4-formatted string with the dealer's partnerId and/or parmaPartnerCode.
// If no ids are available, returns undefined
export const getDealerId = (retailer?: Retailer): Lead['dealerId'] => {
  if (!retailer) return undefined;
  const hasId = !!(retailer?.partnerId || retailer?.parmaPartnerCode);
  return hasId
    ? `${retailer.partnerId ?? 'not-set'}|` +
        `${retailer.parmaPartnerCode ?? 'not-set'}`
    : undefined;
};
