import { useSharedComponentsTranslate } from '@vcc-www/shared-dictionaries';
import {
  type TabGroupState,
  useTab,
  useTabList,
} from '@volvo-cars/react-headless';
import type React from 'react';
import { useEffect } from 'react';
import { translateCapability } from '../../utils/translateCapability';
import type { Capability } from '../types/retailerCapabilities';

type CapabilityTabProps = {
  index: number;
  capability: Capability;
  tabGroupState: TabGroupState;
  onClick: (event: React.MouseEvent<Element, MouseEvent>) => void;
};

const CapabilityTab = ({
  index,
  capability,
  tabGroupState,
  onClick,
}: CapabilityTabProps) => {
  const translate = useSharedComponentsTranslate();
  const { tabProps } = useTab({
    state: tabGroupState,
    index,
  });
  const translatedCapability = translateCapability(capability, translate);
  return (
    <button
      {...tabProps}
      key={tabProps.key}
      className="px-8 font-medium tap-area rounded-sm"
      type="button"
      onClick={onClick}
    >
      {translatedCapability && (
        <span className="flex items-center h-48 border-b-2 border-transparent tab-selected:border-primary transition-colors">
          {translatedCapability}
        </span>
      )}
    </button>
  );
};

type CapabilityTabsProps<T extends Capability> = {
  capabilities: T[];
  defaultSelectedIndex?: number;
  onCapabilityChanged: (c: T) => void;
};

export const CapabilityTabs = <T extends Capability>({
  capabilities,
  defaultSelectedIndex = 0,
  onCapabilityChanged,
}: CapabilityTabsProps<T>) => {
  const { tabListProps, tabGroupState, selectedIndex } = useTabList({
    defaultSelectedIndex,
  });

  useEffect(() => {
    const capability = capabilities[selectedIndex];
    onCapabilityChanged(capability);
  }, [capabilities, onCapabilityChanged, selectedIndex]);

  return (
    <div {...tabListProps} className="flex gap-8 -mx-8">
      {capabilities.map((capability, index) => (
        <CapabilityTab
          onClick={tabListProps.onClick}
          key={capability}
          index={index}
          capability={capability}
          tabGroupState={tabGroupState}
        />
      ))}
    </div>
  );
};
