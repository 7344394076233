import type React from 'react';
import {
  createContext,
  useCallback,
  useContext,
  useMemo,
  useRef,
  useState,
} from 'react';

type SearchInputData = {
  value: string;
  skipFetch: boolean;
  resetPredictions: boolean;
};

export type SearchInputContextValue = {
  inputRef: React.RefObject<HTMLInputElement> | null;
  input: SearchInputData;
  setInput: (newSearchInputData: SearchInputData) => void;
};
const SearchInputContext = createContext<SearchInputContextValue>({
  inputRef: null,
  input: {
    value: '',
    skipFetch: false,
    resetPredictions: false,
  },
  setInput: () => {
    // this is a placeholder, so it is supposed to be empty
  },
});

type SearchInputProviderProps = React.PropsWithChildren<{
  initialSearchValue?: string;
  onSearchValueChange?: (searchValue: string) => void;
}>;

export const SearchInputProvider = ({
  children,
  initialSearchValue,
  onSearchValueChange,
}: SearchInputProviderProps) => {
  const [input, setInputValue] = useState({
    value: initialSearchValue ?? '',
    skipFetch: false,
    resetPredictions: false,
  });
  const inputRef = useRef<HTMLInputElement>(null);

  const setInput = useCallback(
    (newInput: SearchInputData) => {
      onSearchValueChange?.(newInput.value);
      setInputValue(newInput);
    },
    [onSearchValueChange],
  );

  const value = useMemo(
    () => ({
      inputRef,
      input,
      setInput,
    }),
    [input, setInput],
  );

  return (
    <SearchInputContext.Provider value={value}>
      {children}
    </SearchInputContext.Provider>
  );
};

export const useSearchInput = () => useContext(SearchInputContext);
