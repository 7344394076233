const validateLatitudeLongitude = (latitude: string, longitude: string) => {
  const latitudePattern = /^(\+|-)?(90(\.0{0,8})?|([1-8]?\d|0)(\.\d{1,8})?)$/;
  const longitudePattern =
    /^(\+|-)?(180(\.0{1,8})?|((1[0-7][0-9])|([1-9]?[0-9])|0)(\.\d{1,8})?)$/;
  return latitudePattern.test(latitude) && longitudePattern.test(longitude);
};

const validateLatitudeLongitudeNumber = (
  latitude: number,
  longitude: number,
) => {
  const isValidLattitude = latitude <= 90 && latitude >= -90;
  const isValidLongitude = longitude >= -180 && longitude <= 180;
  return isValidLattitude && isValidLongitude;
};

export const isValidLatitudeLongitude = <T extends string | number>({
  latitude,
  longitude,
}: {
  latitude: T;
  longitude: T;
}) => {
  if (typeof latitude === 'string' && typeof longitude === 'string') {
    return validateLatitudeLongitude(latitude, longitude);
  }
  if (typeof latitude === 'number' && typeof longitude === 'number') {
    return validateLatitudeLongitudeNumber(latitude, longitude);
  }
  return false;
};
