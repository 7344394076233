type HeaderProps = {
  name: string;
  distance?: string;
};
export const Header = ({ name, distance }: HeaderProps) => {
  return (
    <div className="flex-row justify-between font-medium">
      <p>{name}</p>
      {distance && <p className="min-w-fit">{distance}</p>}
    </div>
  );
};
