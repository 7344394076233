import type React from 'react';
import { ErrorMessages } from '../../../errorMessages';
import { logError } from '../../logError';
import { RetailerCardMaxi } from './RetailerCardMaxi';

type RetailerCardMaxiDialogProps = {
  onClose: () => void;
  dialogProps: { ref: React.RefObject<HTMLDialogElement> };
  retailerCardMaxiContent: React.ReactNode;
};

export const RetailerCardMaxiDialog = ({
  onClose,
  dialogProps,
  retailerCardMaxiContent,
}: RetailerCardMaxiDialogProps) => {
  const titleId = 'expanded-card-title';
  if (!retailerCardMaxiContent && dialogProps.ref.current?.open) {
    logError(`${ErrorMessages.RETAILER_CARD_MAXI_DIALOG_INVALID_CONTENT}`);
  }
  return (
    <dialog
      className="dialog-large w-sm md:mt-48"
      aria-labelledby={titleId}
      {...dialogProps}
    >
      <>
        <header slot="header">
          <RetailerCardMaxi.Header onClose={onClose} />
        </header>
        <article slot="main">{retailerCardMaxiContent}</article>
      </>
    </dialog>
  );
};
