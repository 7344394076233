import { useSharedComponentsTranslate } from '@vcc-www/shared-dictionaries';
import { IconButton } from '@volvo-cars/react-icons';

type MaxiCardHeaderProps = {
  onClose: () => void;
};

// Designed to work both in Dialog and standalone
export const Header = ({ onClose }: MaxiCardHeaderProps) => {
  const translate = useSharedComponentsTranslate();
  return (
    <>
      <div slot="back">
        <IconButton
          aria-label={translate('RetailerSelector.search.back') || 'Back'}
          bleed
          icon="chevron-back"
          onClick={onClose}
          variant="clear"
        />
      </div>
      <div slot="close">
        <IconButton
          aria-label={translate('RetailerSelector.search.close') || 'Close'}
          bleed
          icon="x"
          onClick={onClose}
          variant="clear"
        />
      </div>
    </>
  );
};
