import type { AutocompleteResponse } from '../src/types/maps';

type ParsedPredictionItem = { text: string; type: 'normal' | 'bold' };

export function parsePrediction(
  prediction: AutocompleteResponse['data'][number],
): ParsedPredictionItem[] {
  const { structured_formatting, description, matched_substrings } = prediction;
  const formattedDescription = structured_formatting.main_text;
  const formattedSubstring = structured_formatting.main_text_matched_substrings;
  if (!formattedDescription || !formattedSubstring) {
    return parseDescriptionWithSubstring(description, matched_substrings);
  }
  return parseDescriptionWithSubstring(
    formattedDescription,
    formattedSubstring,
  );
}
/**
 *
 * the usage of [...text] is a result of the javascript String.prototype.slice() function not being unicode-aware.
 * The string interator however, is uncode aware, so we spread the strings into string[] before splicing and
 * use reduce to create a single string before pushing the result to the return datastructure.
 */
const parseDescriptionWithSubstring = (
  text: string,
  matchedSubstrings: AutocompleteResponse['data'][number]['matched_substrings'],
) => {
  const result: ParsedPredictionItem[] = [];
  let lastOffset = 0;
  if (matchedSubstrings) {
    for (const substr of matchedSubstrings) {
      const prev = [...text].slice(lastOffset, substr.offset);
      if (prev.length) {
        result.push({ text: prev.reduce((p, c) => p + c, ''), type: 'normal' });
        lastOffset = substr.offset;
      }
      const curr = [...text].slice(
        substr.offset,
        substr.length + substr.offset,
      );
      if (curr.length) {
        result.push({ text: curr.reduce((p, c) => p + c, ''), type: 'bold' });
        lastOffset = substr.offset + substr.length;
      }
    }
  }
  if (text.length - lastOffset) {
    result.push({
      text: [...text]
        .slice(lastOffset, text.length)
        .reduce((p, c) => p + c, ''),
      type: 'normal',
    });
  }
  return result;
};
