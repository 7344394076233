import { useVolvoCarsUrl } from '@vcc-www/volvo-cars-url';
import { View } from 'vcc-ui';

type ExitPreviewProps = {
  retailerName?: string;
};
export const ExitPreview = ({ retailerName }: ExitPreviewProps) => {
  const { siteSlug } = useVolvoCarsUrl();
  const pageURL = retailerName
    ? `/api/dealers/exit-preview?site=${siteSlug}&retailer=${retailerName}`
    : `/api/dealers/exit-preview?site=${siteSlug}`;
  return (
    <a href={pageURL}>
      <View
        padding={1}
        margin={2}
        extend={({ theme: { color } }) => ({
          border: `1px solid ${color.ornament.border}`,
          background: color.background.secondary,
          borderRadius: 4,
          position: 'fixed',
          left: 0,
          bottom: 0,
          zIndex: 999,
        })}
      >
        <small className="micro">End preview</small>
      </View>
    </a>
  );
};
