import { SearchBlockWidth } from '../../constants/styling';
import { MIN_ZOOM_LEVEL } from '../mapConstants';

const searchBlockTopPadding = 24;
const searchBlockMinHeight = 276;
const searchBlockInlineStart = 96;
const PIXEL_OFFSET_X = -(SearchBlockWidth + searchBlockInlineStart) / 2;
const PIXEL_OFFSET_Y = -(searchBlockMinHeight + 24) / 2;
const MAX_ZOOM_LEVEL = 20;

type MapConfigType = {
  lat?: number;
  lng?: number;
  zoom?: number;
  maxZoom?: number;
  minZoom?: number;
  bounds?: google.maps.LatLngBounds;
  offset?: 'lat' | 'negativeLng' | 'positiveLng';
};

const clamp = (num: number, min: number, max: number) =>
  Math.max(min, Math.min(num, max));

const panWithOffset = ({
  map,
  mapConfig,
  offsetDirection,
}: {
  map: google.maps.Map;
  mapConfig: MapConfigType;
  offsetDirection: 'lat' | 'negativeLng' | 'positiveLng';
}) => {
  const newCenter = getOffsetCenter({ map, mapConfig, offsetDirection });
  if (newCenter) {
    map.panTo({
      lat: newCenter.lat(),
      lng: newCenter.lng(),
    });
  }
};

export const updateMapPanBoundsZoom = (
  map: google.maps.Map | null | undefined,
  mapConfig: MapConfigType,
  debounceTimeout = 0,
) => {
  if (!map || !mapConfig || typeof window === 'undefined') {
    return;
  }
  debounce(debounceTimeout, () => {
    if (mapConfig.lat && mapConfig.lng) {
      const { lat, lng } = mapConfig;
      if (mapConfig.offset) {
        panWithOffset({ map, mapConfig, offsetDirection: mapConfig.offset });
      } else {
        map.panTo({ lat, lng });
      }
    }

    if (mapConfig.bounds) {
      if (!mapConfig.zoom) {
        map.setZoom(MAX_ZOOM_LEVEL);
      }
      map.fitBounds(mapConfig.bounds, {
        left:
          mapConfig.offset === 'positiveLng' ||
          mapConfig.offset === 'negativeLng'
            ? SearchBlockWidth + searchBlockInlineStart
            : 0,
        top:
          mapConfig.offset === 'lat'
            ? searchBlockMinHeight + searchBlockTopPadding
            : 0,
      });
      if (mapConfig.lat && mapConfig.lng) {
        if (mapConfig.offset) {
          offsetCenterOfMap({
            map,
            lat: mapConfig.lat,
            lng: mapConfig.lng,
            offsetDirection: mapConfig.offset,
          });
        } else {
          map.panToBounds(mapConfig.bounds);
        }
      }
    }

    if (mapConfig.zoom || mapConfig.minZoom || mapConfig.maxZoom) {
      const toSetZoom = mapConfig.zoom ?? map.getZoom() ?? MAX_ZOOM_LEVEL;
      map.setZoom(
        clamp(
          toSetZoom,
          mapConfig.minZoom ?? MIN_ZOOM_LEVEL,
          mapConfig.maxZoom ?? MAX_ZOOM_LEVEL,
        ),
      );
    }
  })();
};

type Timer = ReturnType<typeof setTimeout>;

// biome-ignore lint/suspicious/noExplicitAny: any is appropriate here
const debounce = (timeout: number, fn: (...args: any[]) => any) => {
  let timer: Timer | undefined;
  // biome-ignore lint/suspicious/noExplicitAny: any is appropriate here
  return function (this: any, ...args: any[]) {
    if (timeout <= 0) {
      //No timeout we run function direct
      fn.apply(this, args);
    }

    clearTimeout(timer);
    timer = setTimeout(() => {
      fn.apply(this, args);
    }, timeout);
  };
};

const offsetCenterOfMap = ({
  map,
  lat,
  lng,
  offsetDirection,
}: {
  map: google.maps.Map;
  lat: number;
  lng: number;
  offsetDirection: 'lat' | 'negativeLng' | 'positiveLng';
}) => {
  const newCenter = getOffsetCenter({
    map,
    mapConfig: { lat, lng, zoom: map.getZoom() },
    offsetDirection,
  });
  if (newCenter) {
    map.setCenter({ lat: newCenter.lat(), lng: newCenter.lng() });
  } else {
    map.setCenter({ lat, lng });
  }
};

/* 
  https://developers.google.com/maps/documentation/javascript/coordinates#pixel-coordinates
*/
const getOffsetCenter = ({
  map,
  mapConfig,
  offsetDirection,
}: {
  map: google.maps.Map;
  mapConfig: MapConfigType;
  offsetDirection: 'lat' | 'negativeLng' | 'positiveLng';
}) => {
  const { zoom, lat, lng } = mapConfig;
  const projection = map.getProjection();
  if (!zoom || !projection || !lat || !lng) {
    return;
  }
  const worldCoordinateOfCenter = projection.fromLatLngToPoint({
    lat,
    lng,
  });
  if (!worldCoordinateOfCenter) {
    return;
  }

  const mapScale = 2 ** zoom;
  const worldCoordinateXOffset = PIXEL_OFFSET_X / mapScale;
  const worldCoordinateYOffset = PIXEL_OFFSET_Y / mapScale;
  // Create the new point for offset
  const newCenterPoint = new google.maps.Point(
    worldCoordinateOfCenter.x +
      (offsetDirection === 'positiveLng'
        ? worldCoordinateXOffset
        : offsetDirection === 'negativeLng'
          ? worldCoordinateXOffset * -1
          : 0),
    worldCoordinateOfCenter.y +
      (offsetDirection === 'lat' ? worldCoordinateYOffset : 0),
  );
  return projection.fromPointToLatLng(newCenterPoint);
};
