import type { DictionaryItemTypes } from '@vcc-www/shared-dictionaries/DictionaryItemTypes';
import { ErrorMessages } from '../errorMessages';
import { logError } from '../src/logError';
import type { Capability } from '../src/types/retailerCapabilities';

export const translateCapability = (
  capability: Capability,
  translate: (itemKey: keyof DictionaryItemTypes) => string,
) => {
  switch (capability) {
    case 'service':
      return (
        translate('RetailerSelector.capabilities.serviceCapability') ||
        'Service'
      );
    case 'used car sales':
      return (
        translate('RetailerSelector.capabilities.usedCarSalesCapability') ||
        'Used car sales'
      );
    case 'sales':
      return (
        translate('RetailerSelector.capabilities.salesCapability') || 'Sales'
      );
    case 'collision center':
      return (
        translate('RetailerSelector.capabilities.collisionCenterCapability') ||
        'Collision center'
      );
    case 'diplomat':
      return (
        translate('RetailerSelector.capabilities.diplomatCapability') ||
        'Diplomat'
      );
    case 'test drive':
      return (
        translate('RetailerSelector.capabilities.testDriveCapability') ||
        'Test drive'
      );
    default:
      logError(ErrorMessages.INVALID_CAPABILITY);
      return '';
  }
};
