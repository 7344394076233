import { useCurrentMarketSite } from '@vcc-www/market-sites';
import { useEffect, useState } from 'react';
import { useStore } from '../providers/StoreProvider';
import type { Retailer } from '../types/retailer';
import { formatDistance } from './utils/formatDistance';
import { getMapLinkQuery } from './utils/getMapLinkQuery';
import { isEqualRetailer } from './utils/isEqualRetailer';

type RetailerInfo = {
  distance?: string;
  addressHref: string;
  addressLines: string[];
  isSelectedRetailer: boolean;
  name: string;
  phoneNumbers: {
    retailer: string;
    service: string;
    main: string;
  };
};
export function useRetailerCardInfo(retailer: Retailer): RetailerInfo {
  const { address: currentAddress, selectedRetailer } = useStore();
  const hasUserAddress = !!(
    currentAddress?.coords?.latitude || currentAddress?.place_id
  );
  const [isSelectedRetailer, setIsSelectedRetailer] = useState(false);
  const {
    distanceFromPointMiles,
    distanceFromPointKm,
    name,
    addressLine1,
    addressLine2,
    mapLocation,
    coordinatesMapLink,
    longitude,
    latitude,
    phoneNumbers,
  } = retailer;

  useEffect(() => {
    if (isEqualRetailer(retailer, selectedRetailer)) {
      setIsSelectedRetailer(true);
    } else {
      setIsSelectedRetailer(false);
    }
  }, [retailer, selectedRetailer]);

  const mapLinkQuery = getMapLinkQuery({
    coordinatesMapLink,
    name,
    addressLine1,
    addressLine2,
    longitude,
    latitude,
  });

  const { locale, roadLengthUnit } = useCurrentMarketSite();

  const distance =
    roadLengthUnit === 'mile' ? distanceFromPointMiles : distanceFromPointKm;
  const formattedDistance = hasUserAddress
    ? formatDistance(distance, locale, roadLengthUnit)
    : undefined;

  const addressLines = [
    ...addressLine1.split('\n'),
    ...addressLine2.split('\n'),
  ];

  return {
    isSelectedRetailer,
    distance: formattedDistance,
    addressHref: mapLocation || `https://maps.google.com/?q=${mapLinkQuery}`,
    addressLines,
    name,
    phoneNumbers,
  };
}
