import type { SpecialDate, TimeInterval } from '../src/types/graphql';

export type FormattedSpecialHours = {
  date: string;
  timings: string[];
};

const getFormattedSpecialDate = (
  date: string,
  timings: TimeInterval[],
  locale: string,
) => {
  const dayOfWeek = new Intl.DateTimeFormat(locale, { weekday: 'long' }).format(
    new Date(date),
  );
  const formattedStartDate = `${dayOfWeek} ${date}`;

  const formattedTimings =
    timings.length > 0
      ? timings.map((timing) => {
          return `${timing.start} - ${timing.end}`;
        })
      : // TODO: Localise this one
        ['Closed'];

  return {
    date: formattedStartDate,
    timings: formattedTimings,
  };
};

export const getFormattedSpecialHours = (
  specialDates: SpecialDate[],
  locale: string,
): FormattedSpecialHours[] => {
  const datesToFormat: { startDate: string; timings: TimeInterval[] }[] = [];

  for (const date of specialDates) {
    const { startDate, endDate, timings } = date;

    if (startDate !== endDate) {
      const start = new Date(startDate);
      const end = new Date(endDate);

      const current = start;
      while (current <= end) {
        datesToFormat.push({
          startDate: current.toISOString().split('T')[0],
          timings,
        });
        current.setDate(current.getDate() + 1);
      }
    } else {
      datesToFormat.push(date);
    }
  }

  const yesterday = new Date();
  yesterday.setDate(yesterday.getDate() - 1);
  const fortnightInFuture = new Date();
  fortnightInFuture.setDate(fortnightInFuture.getDate() + 14);

  return datesToFormat
    .filter(
      (date) =>
        new Date(date.startDate) > yesterday &&
        new Date(date.startDate) < fortnightInFuture,
    )
    .map((date) => {
      return getFormattedSpecialDate(date.startDate, date.timings, locale);
    });
};
