import {
  useRetailers,
  useSearchInput,
  useStore,
} from '@vcc-package/retailer-selector';
import { useContext } from 'react';
import { SelectorContext } from 'src/providers/SelectorProvider';

export { useStore, useSearchInput, useRetailers };
export const useSelectorSettings = () => useContext(SelectorContext);
