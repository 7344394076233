import { useSharedComponentsTranslate } from '@vcc-www/shared-dictionaries';
import styles from './ClusterPin.module.css';

export const ClusterPin = ({
  count,
  onClick,
}: {
  count: number;
  onClick: () => void;
}) => {
  const translate = useSharedComponentsTranslate();
  return (
    <button
      type="button"
      onClick={onClick}
      className="h-32 w-32 rounded-full bg-always-black flex items-center justify-center hover:scale-zoomed transition-transform"
      aria-label={
        translate('RetailerSelector.map.mapClusterPinAriaLabel') ||
        'Volvo retailers map pin'
      }
    >
      <p className={`${styles.label} font-14 font-medium text-always-white`}>
        {count}
      </p>
    </button>
  );
};
