import type { SiteSlug } from '@volvo-cars/market-sites';

export const enabledSiteslugs: Partial<SiteSlug>[] = [
  'fr-be',
  'nl-be',
  'de',
  'fi',
  'fr',
  'it',
  'no',
  'pl',
  'se',
  'uk',
  'nl',
  'it-ch',
  'de-ch',
  'fr-ch',
  'es',
  'dk',
  'pt',
  'au',
  'lu',
  'jp',
  'at',
  'gr',
  'hu',
  'tr',
  'fr-ca',
  'en-ca',
  'my',
  'th-th',
  'en-th',
  'uk-ua',
  'in',
  'tw',
  'co',
  'ie',
  'hr',
  'mx',
  'br',
  'py',
  'cz',
  'ar',
  'cl',
  'si',
  'nz',
  'za',
  'sk',
  'intl',
  'bg',
  'ar-eg',
  'en-eg',
  'il',
  'lv-lv',
  'ro-md',
  'ru-md',
  'ro',
  'ba',
  'gt',
  'mt',
  'et-ee',
  'lt-lt',
  'cy',
  'us',
  'al',
  'hy-am',
  'az',
  'ge',
  'xk',
  'mu',
  'me',
  'ma',
  'mk',
  'rs',
  'tn',
  'uz',
  'ar-bh',
  'en-bh',
  'ar-jo',
  'en-jo',
  'ar-kw',
  'en-kw',
  'lb',
  'ar-om',
  'en-om',
  'ar-qa',
  'en-qa',
  'ar-ae',
  'en-ae',
];
