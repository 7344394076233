import { useCurrentMarketSite } from '@vcc-www/market-sites';
import { markets } from '@volvo-cars/market-sites/src/markets';
import type React from 'react';
import {
  type Dispatch,
  type SetStateAction,
  createContext,
  useCallback,
  useContext,
  useMemo,
  useState,
} from 'react';
import { ErrorMessages } from '../../errorMessages';
import { useAorRetailers } from '../hooks/useAorRetailers';
import { useCoordinates } from '../hooks/useCoordinates';
import { useSortRetailers } from '../hooks/useSortRetailers';
import { logError } from '../logError';
import type { AorRetailer, Retailer } from '../types/retailer';
import {
  ALL_CAPABILITIES,
  type Capability,
} from '../types/retailerCapabilities';
import { useMarketConfig } from './MarketConfigProvider';
import { useStore } from './StoreProvider';

type RetailersProviderProps = React.PropsWithChildren<{
  initialRetailers: Retailer[];
}>;
export const RetailersProvider = ({
  children,
  initialRetailers: retailers,
}: RetailersProviderProps) => {
  const { regionCode, siteSlug } = useCurrentMarketSite();
  const { disabledFilters } = useMarketConfig();
  const { sessiontoken, dispatch, address } = useStore();
  const [activeCapabilityFilters, setActiveCapabilityFilters] = useState<
    Capability[]
  >([]);

  const place_id = address?.place_id;
  const refreshSessiontoken = useCallback(() => {
    dispatch({ type: 'REFRESH_SESSION_TOKEN' });
  }, [dispatch]);

  if (!retailers) {
    logError(ErrorMessages.NO_INITIAL_RETAILERS);
  }

  const {
    data: aorData,
    isLoading: aorIsLoading,
    error: aorError,
  } = useAorRetailers({
    siteSlug,
    sessiontoken,
    place_id,
    coordinates: address?.coords,
  });

  const aorRetailers = aorData?.aorRetailers;
  const aorCoordinates = aorData?.coordinates;

  //undefined if UseMyLocationIsNotUsed
  const countryOfUseMyLocation = aorData?.countryOfUser;

  const userInSameCountryAsMarket =
    !countryOfUseMyLocation ||
    (Object.keys(markets).includes(countryOfUseMyLocation) &&
      countryOfUseMyLocation === regionCode);

  const {
    coordinates,
    error: coordinatesError,
    isLoading: coordinatesIsLoading,
  } = useCoordinates({
    coordinates: aorCoordinates || address?.coords,
    place_id: address?.place_id,
    sessiontoken,
  });
  // Sort by distance and set aorRetailers at top
  const sortedRetailers = useSortRetailers({
    capabilities: activeCapabilityFilters,
    coordinates,
    refreshSessiontoken,
    retailers,
    aorRetailers: userInSameCountryAsMarket ? aorRetailers : [],
    aorIsLoading,
  });
  const isLoading = aorIsLoading || coordinatesIsLoading;
  const error = aorError || coordinatesError;
  const availableCapabilityFilters = useMemo(
    () =>
      ALL_CAPABILITIES.filter(
        (cap) =>
          !disabledFilters?.includes(cap) &&
          retailers?.some((ret) => ret.capabilities.includes(cap)),
      ),
    [retailers, disabledFilters],
  );

  const retailersValue = useMemo(
    () => ({
      setActiveCapabilityFilters,
      activeCapabilityFilters,
      availableCapabilityFilters,
      retailers: sortedRetailers,
      isLoading,
      error,
      aorRetailers,
    }),
    [
      activeCapabilityFilters,
      sortedRetailers,
      isLoading,
      error,
      aorRetailers,
      availableCapabilityFilters,
    ],
  );

  return (
    <RetailersContext.Provider value={retailersValue}>
      {children}
    </RetailersContext.Provider>
  );
};

export type RetailersContextValue = {
  setActiveCapabilityFilters: Dispatch<SetStateAction<Capability[]>>;
  activeCapabilityFilters: Capability[];
  availableCapabilityFilters: Capability[];
  retailers?: Retailer[];
  isLoading: boolean;
  // biome-ignore lint/suspicious/noExplicitAny: could be any, we don't know for a fact that it's an Error. Consider refactoring
  error: any;
  aorRetailers: AorRetailer[] | undefined;
};
const initialContext = {
  setActiveCapabilityFilters: () => {
    // This is a placeholder, so it's supposed to be empty
  },
  activeCapabilityFilters: [],
  availableCapabilityFilters: [],
  retailers: undefined,
  isLoading: false,
  error: null,
  aorRetailers: [],
};

export const RetailersContext =
  createContext<RetailersContextValue>(initialContext);

export const useRetailers = () => {
  const context = useContext(RetailersContext);
  if (context === initialContext) {
    logError(ErrorMessages.USE_RETAILERS_MUST_BE_USED_IN_RETAILERS_PROVIDER);
  }
  return context;
};
