import { getDictionariesProvider } from '@vcc-www/react-translate';
import { ErrorMessages } from 'src/constants/errorMessages';
import type { DictionaryItemTypes } from 'src/content-management/DictionaryItemTypes';
import { logError } from 'src/utils/logError';

const onError = (e: string) => {
  logError(`${ErrorMessages.RETAILER_TRANSLATION_ERROR}, ${e}`);
};

export const { useTranslate, DictionariesProvider } =
  getDictionariesProvider<DictionaryItemTypes>({ onError });
