import { useRef } from 'react';
import { usePointerScroll } from '../hooks/usePointerScroll';
import styles from './PillGroup.module.css';
type PillGroupProps = {
  pillTitles: string[];
  hasHorizontalScroll: boolean;
};

export const PillGroup = ({
  pillTitles,
  hasHorizontalScroll,
}: PillGroupProps) => {
  const scrollRef = useRef<HTMLDivElement>(null);
  usePointerScroll(scrollRef);

  return (
    <div
      /* min-h-24 instead of min-h-fit due to safari issue to compute overflow + flexbox height */
      className={`flex gap-8 ${hasHorizontalScroll ? `overflow-x-auto -mx-24 pl-24 scrollbar-none min-h-24 ${styles.selectNone}` : 'flex-wrap'}`}
      ref={scrollRef}
    >
      {pillTitles.map((title) => (
        <div
          key={title}
          className="micro rounded-full py-2 px-8 w-fit border-ring whitespace-nowrap bg-always-white"
          data-testid={`dealer:${title}-pill`}
        >
          {title}
        </div>
      ))}
    </div>
  );
};
