import type React from 'react';
import styles from './SearchFrame.module.css';

type SearchFrameProps = {
  header?: React.ReactNode;
  main?: React.ReactNode;
  scrollRef?: React.RefObject<HTMLDivElement>;
};

export const SearchFrame = ({ header, main, scrollRef }: SearchFrameProps) => {
  return (
    <div
      className={`${styles.search} bg-secondary p-24 rounded-md flex-col h-full gap-24 sticky`}
    >
      {header && header}
      {main && (
        <div
          className="overflow-y-auto scrollbar-none -mb-24 lg:pb-24"
          ref={scrollRef}
        >
          {main}
        </div>
      )}
    </div>
  );
};
