import type React from 'react';
import { Content } from './RetailerCardMaxi.Content';
import { Header } from './RetailerCardMaxi.Header';
import styles from './RetailerCardMaxi.module.css';

type RetailerCardMaxiProps = {
  onClose: () => void;
  children: React.ReactNode;
};

export const RetailerCardMaxi = ({
  onClose,
  children,
}: RetailerCardMaxiProps) => {
  return (
    <div
      className={`${styles.wrapper} scrollbar-none pb-24 -mb-24 overflow-auto flex-col`}
    >
      <div className="flex justify-between bg-always-white rounded-t p-24 sticky top-0 z-navigation">
        <Header onClose={onClose} />
      </div>
      <div className="bg-always-white rounded-b px-24 pb-24 md:mb-24 lg:mb-0">
        {children}
      </div>
    </div>
  );
};

RetailerCardMaxi.Content = Content;
RetailerCardMaxi.Header = Header;
