import { useState } from 'react';
import type { OpeningHours } from '../types/graphql';

export type SelectionCapabilities = 'sales' | 'service';

export const useCapabilitySelection = ({
  openingHours,
  phoneNumbers,
  defaultCapability,
}: {
  openingHours: {
    retailer: OpeningHours | null;
    service: OpeningHours | null;
  };
  phoneNumbers: {
    main?: string;
    retailer: string;
    service: string;
  };
  defaultCapability?: SelectionCapabilities;
}) => {
  const [selectedCapability, setSelectedCapability] =
    useState(defaultCapability);
  const selectedOpeningHours =
    selectedCapability === 'sales'
      ? openingHours.retailer
      : selectedCapability === 'service'
        ? openingHours.service
        : null;

  const selectedPhoneNumber =
    selectedCapability === 'sales'
      ? phoneNumbers.retailer
      : selectedCapability === 'service'
        ? phoneNumbers.service
        : undefined;

  return {
    selectedOpeningHours,
    selectedPhoneNumber,
    setSelectedCapability,
    selectedCapability,
  };
};
