import type { Retailer } from '@vcc-package/retailer-selector';
import type React from 'react';
import { createContext } from 'react';

export type SelectorSettings = {
  useSelector: boolean;
  onRetailerSelect: (retailer: Retailer) => void;
};

export const SelectorContext = createContext<SelectorSettings>({
  useSelector: false,
  // biome-ignore lint/suspicious/noEmptyBlockStatements: this is meant to be a nop
  onRetailerSelect: () => {},
});

type SelectorProviderProps = React.PropsWithChildren<{
  initialSettings: SelectorSettings;
}>;
export const SelectorProvider = ({
  initialSettings,
  children,
}: SelectorProviderProps) => {
  return (
    <SelectorContext.Provider value={initialSettings}>
      {children}
    </SelectorContext.Provider>
  );
};
